// import gsap from "gsap";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const MainV2TokenValue = () => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const coinRef = useRef(null);
  const textRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   const container = containerRef.current;
  //   const content = contentRef.current;
  //   const coin = coinRef.current;
  //   const text = textRef.current;

  //   gsap.timeline({
  //     scrollTrigger: {
  //       trigger: container,
  //       pin: true,
  //       start: "top top",
  //       end: "bottom bottom-=350",
  //       scrub: 0.1,
  //     }
  //   })
  //   .fromTo(coin,
  //     { opacity: 0, force3D: true },
  //     { opacity: 1, duration: 9, ease: "power2.out", force3D: true }
  //   )
  //   .fromTo(content, 
  //     { y: "100vh" }, { y: "550px", duration: 20 }
  //   );
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatStringBreaks = (text) => {
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="hidden lg:inline relative w-full max-w-[1920px] overfow-y-hidden lg:py-8 xl:py-10 2xl:py-12">

      {/* Start and End Fade Out Overlays */}
      <div className="hidden xl:block absolute top-0 left-0 w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="hidden xl:block absolute top-0 right-0 w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      
      <div ref={containerRef} className="h-[100vh] w-full">
        <div className="absolute inset-0 z-[1] gradient-overlay">
          <img
            className="w-full h-auto object-cover bg-fixed"
            src="/assets/mainV2/tokenvalue-bg.webp"
            alt="Background"
          />
        </div>
        <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none z-[30]">
          {!isMobile ? (
            <video
              ref={coinRef}
              src="https://storage.animara.world/coin-turntable.webm"
              className="w-1/5 h-auto z-200 mt-[-4rem] bg-transparent"
              autoPlay
              loop
              muted
              playsInline
              style={{
                willChange: 'transform, opacity',
                backgroundColor: 'transparent'
              }}
            />
          ) : (
            <img
              className="w-2/3 h-auto z-[200]"
              src="/assets/mainV2/coin-turntable.webp"
              alt="Background"
            />
          )}
          
          <div ref={textRef} className="flex flex-col items-center justify-center">
            <h1 className="text-[#FFC85A] text-6xl font-normal font-bigNoodle transition-opacity duration-[3000ms] mt-[1rem]">
              {t('anitoken value title')}
            </h1>
            <p
              className="text-[#FFFFFF] text-center text-base font-medium font-outfit transition-opacity duration-[3000ms] mt-[1rem]"
            >
              {formatStringBreaks(t("anitoken value desc"))}
            </p>
          </div>
        </div>
      </div>
      <div ref={contentRef} className="absolute inset-0 flex flex-col h-[100vh] bg-transparent items-center justify-center z-[100]">
        <div className="flex flex-col w-[85%] justify-between">
          <div className="flex flex-col w-[28%] self-start hover:scale-105 transition-all duration-300">
            <h1 className="text-[#FFF7EA] text-3xl lg:text-4xl font-normal font-bigNoodle transition-opacity duration-[1500ms]">
              {formatStringBreaks(t('anitoken value point title 3'))}
            </h1>
            <p className="text-[#C5C5C5] text-sm font-normal font-outfit transition-opacity duration-[1500ms] mt-[0.5rem]">
              {formatStringBreaks(t('anitoken value point desc 3'))}
            </p>
          </div>
          <div className="flex flex-col w-[28%] self-end my-[4rem] hover:scale-105 transition-all duration-300">
            <h1 className="text-[#FFF7EA] text-3xl lg:text-4xl font-normal font-bigNoodle transition-opacity duration-[1500ms]">
              {formatStringBreaks(t('anitoken value point title 1'))}
            </h1>
            <p className="text-[#C5C5C5] text-sm font-normal font-outfit transition-opacity duration-[1500ms] mt-[0.5rem]">
              {formatStringBreaks(t('anitoken value point desc 1'))}
            </p>
          </div>
          <div className="flex flex-col w-[28%] self-start hover:scale-105 transition-all duration-300">
            <h1 className="text-[#FFF7EA] text-3xl lg:text-4xl font-normal font-bigNoodle transition-opacity duration-[1500ms]">
              {formatStringBreaks(t('anitoken value point title 2'))}
            </h1>
            <p className="text-[#C5C5C5] text-sm font-normal font-outfit transition-opacity duration-[1500ms] mt-[0.5rem]">
              {formatStringBreaks(t('anitoken value point desc 2'))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainV2TokenValue;
