import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronDown from "../../component/icons/ChevronDown";
import ChevronLeft from "../../component/icons/ChevronLeft";
import ChevronRight from "../../component/icons/ChevronRight";
import Slider from "react-slick";

const AniToEarnTapToEarn = ({ setIsOpenVideo, setIsPageReady }) => {
  const { t } = useTranslation();
  const [isOpenShowMore, setIsOpenShowMore] = useState(false);
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
  let sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.slickNext();
  };
  const handlePrev = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false
  };

  // const [currentVideo] = useState();

  const videosList = [
    {
      source: "/assets/aniearn/cover/Tap_to_Earn_Trailer.png",
      title: "Tap to Earn Trailer",
      videoSource: "https://storage.animara.world/ANI_T2E_Trailer.mp4"
    },
    {
      source: "/assets/aniearn/cover/Tap_to_Earn_Promotional.png",
      title: "Tap to Earn Promotional",
      videoSource: "https://storage.animara.world/ANI_T2E_PromotionalVid.mp4"
    }
  ];

  const handleOpenVideoPlayer = (videoSource) => {
    setIsOpenVideo(videoSource)
  }

  const formatStringBreaks = (text, colorText) => {
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part.split(new RegExp(`(${colorText})`, 'gi')).map((segment, segmentIndex) => (
          <React.Fragment key={segmentIndex}>
            {segment === colorText ? (
              <span className="text-[#FFAA00] font-bold">{segment}</span>
            ) : (
              segment
            )}
          </React.Fragment>
        ))}
        <br />
      </React.Fragment>
    ));
  };  

  const isPageLoaded = useCallback(() => {
    setIsPageReady(true);
  }, [setIsPageReady]);

  return (
    <div className="relative h-auto lg:h-[100vh] w-screen max-w-[1920px] z-[30] flex flex-col items-center justify-center bg-[#000000] mt-[1rem]">

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="hidden lg:block absolute inset-0 z-[-1]">
        <img
          className="w-screen object-cover h-full"
          src="/assets/aniearn/tap-to-earn-background.webp"
          alt="Background"
          onLoad={isPageLoaded}
        />
      </div>

      {/* Radial Gradient */}
      <div className="hidden lg:block absolute inset-0 w-screen h-full opacity-50 bg-[radial-gradient(circle_at_center_bottom,_#13253800_50%,_#03060A99_74%,_#00000000_100%)] pointer-events-none bg-[transparent] z-[-1]"></div>

      {/* Darker background */}
      <div className="hidden lg:block absolute inset-0 opacity-5 w-screen h-full bg-[#000000] pointer-events-none z-[10]"></div>

      <div className="hidden lg:block absolute h-[30vh] top-0 opacity-100 w-screen bg-gradient-to-b from-[#000000] from-0% via-[#000000]/8 via-42% to-[transparent] to-100% pointer-events-none z-[10]"></div>
      <div className="hidden lg:block absolute h-[30vh] bottom-0 opacity-100 w-screen bg-gradient-to-t from-[#000000] from-0% via-[#000000]/8 via-42% to-[transparent] to-100% pointer-events-none z-[10]"></div>
      <div className="absolute inset-0 opacity-100 w-screen h-full bg-gradient-to-l from-[#000000] from-0% via-[#000000]/8 via-42% to-[transparent] to-100% pointer-events-none z-[-2]"></div>

      <div className="lg:hidden absolute h-[23vh] bottom-0 mb-[1rem] opacity-80 w-screen bg-gradient-to-b from-[#00090F] from-0% via-[#035D9F] via-50% to-[#000000] to-100% pointer-events-none z-[-1]"></div>

      <div className="lg:hidden relative w-screen h-[60vh]">
        <div className="absolute inset-0 w-screen h-full opacity-50 bg-[radial-gradient(circle_at_center_bottom,_#13253800_50%,_#03060A99_74%,_#00000000_100%)] pointer-events-none bg-[transparent] z-[-1]"></div>

        <div className="hidden lg:block absolute h-[30vh] top-0 opacity-100 w-screen bg-gradient-to-b from-[#000000] from-0% via-[#000000]/8 via-42% to-[transparent] to-100% pointer-events-none z-[10]"></div>
        <div className="hidden lg:block absolute h-[30vh] bottom-0 opacity-100 w-screen bg-gradient-to-t from-[#000000] from-0% via-[#000000]/8 via-42% to-[transparent] to-100% pointer-events-none z-[10]"></div>
        <div className="absolute inset-0 opacity-100 w-screen h-full bg-gradient-to-l from-[#000000] from-0% via-[#000000]/8 via-42% to-[transparent] to-100% pointer-events-none z-[-2]"></div>

        <div className="absolute inset-0 z-[-1]">
          <img
            className="w-screen object-cover h-full"
            src="/assets/aniearn/tap-to-earn-background_mobile.webp"
            alt="Background"
          />
        </div>
      </div>
      <div className="relative w-[80%] lg:h-[80%] mt-[-30vh] lg:mt-0 pb-[3rem] lg:pb-0 gap-[3rem] lg:gap-0 flex flex-col lg:flex-row justify-between items-center z-[100]">
        <div className="flex flex-row lg:flex-col w-full lg:w-fit lg:gap-[2rem] items-end lg:items-start justify-between lg:justify-center">
          <h1 className="text-[#FFF7EA] text-7xl lg:text-[10rem] lg:leading-[9rem] font-normal font-bigNoodle transition-opacity duration-1000">
            {formatStringBreaks(t('tap to earn'), "to")}
          </h1>
          <a
            type="button"
            href="https://app.animara.world/login"
            className="mb-[0.5rem] lg:mb-0 px-[3rem] py-[1rem] bg-[#FFB23F] scale-100 hover:scale-110 transition-all duration-300 drop-shadow-lg text-white rounded-3xl text-base lg:text-lg xl:text-xl font-outfit font-semibold xl:font-bold z-[30]"
          >
            {t("Play Now")}
          </a>
        </div>
        <div className="w-full lg:w-[40%] h-full flex flex-col justify-center gap-[1rem]">
          <div className="flex flex-col gap-[1rem] lg:gap-[1.5rem]">
            <h1 className="text-[#FFC85A] text-5xl font-normal font-bigNoodle transition-opacity duration-1000 drop-shadow-2xl hidden lg:block">
              {t('tap-to-earn')}
            </h1>
            <p className={`text-[#FFFFFF] text-left text-sm font-normal font-outfit transition-opacity duration-1000 drop-shadow-2xl`}>
              {formatStringBreaks(t("tap-to-earn desc"), "Tap to Earn!")}
            </p>
          </div>
          <div className={`${isOpenShowMore ? "max-h-[700px] opacity-100" : "max-h-0 opacity-0 z-[-100] mt-[-20px]"} transition-all duration-500 ease-in-out`}>
            <p className="text-[#FFFFFF] text-left text-sm font-normal font-outfit transition-opacity duration-1000 drop-shadow-2xl">
              {formatStringBreaks(t("tap-to-earn desc more"), "Tap to Earn!")}
            </p>
          </div>
          <button className="flex items-center gap-[1rem]" onClick={() => { setIsOpenShowMore(prev => !prev) }}>
            <p className="text-[#80E8FF] text-left text-sm font-normal capitalize font-outfit transition-opacity duration-1000">
              {isOpenShowMore ? t("show less") : t("show more")}
            </p>
            <div className={`${isOpenShowMore ? "-rotate-180" : ""}`}>
              <ChevronDown fill={"#80E8FF"} width="12px" />
            </div>
          </button>
          <div className="w-full overflow-hidden flex flex-col gap-[1rem] lg:rounded-[20px] lg:p-[30px] lg:backdrop-blur-[6px] mt-[1rem] lg:mt-[3rem]">
            <div className="hidden lg:block absolute opacity-80 w-full h-full inset-0 bg-gradient-to-b from-[#00111E] from-0% to-[#035D9F] to-100% pointer-events-none z-[-1]"></div>
            <div className="w-full flex justify-between items-center">
              <h1 className="text-[#FFFFFF] text-4xl font-normal font-bigNoodle transition-opacity duration-1000">
                {t('watch video')}
              </h1>
              <div className="flex gap-[0.5rem]">
                <button
                  className={`w-[30px] h-[30px] rounded-[6px] ${currentSlideNumber === 0 ? "bg-[#3C3C3C] " : "bg-[#0163BE] hover:bg-[#49DEFF]"} transition-all duration-300 ease-in-out flex items-center justify-center`}
                  onClick={handlePrev}
                >
                  <ChevronLeft width="14" height="14" />
                </button>
                <button
                  className={`w-[30px] h-[30px] rounded-[6px] ${(currentSlideNumber + 2) === videosList.length ? "bg-[#3C3C3C]" : "bg-[#0163BE] hover:bg-[#49DEFF]"} transition-all duration-300 ease-in-out flex items-center justify-center`}
                  onClick={handleNext}
                >
                  <ChevronRight width="14" height="14" />
                </button>
              </div>
            </div>
            <Slider
              ref={slider => {
                sliderRef = slider;
              }}
              afterChange={(currentSlide, nextSlide) => setCurrentSlideNumber(currentSlide)}
              className="w-full"
              {...settings}
            >
              {videosList.map((item, index) => (
                <div key={index} className={`flex flex-col w-full`}>
                  <div className="relative w-full border-solid border-[3px] border-[transparent] hover:border-[#FFC85A] transition-all duration-300 mb-2 rounded-[10px]" onClick={() => { handleOpenVideoPlayer(item.videoSource) }}>
                    <img
                      className="h-auto object-cover rounded-[10px]"
                      src={item.source}
                    />
                    <div className="absolute inset-0 bg-[#000000] opacity-40 rounded-[10px]"></div>
                  </div>
                  <p className="text-[#C5C5C5] text-left text-sm font-normal capitalize font-outfit transition-opacity duration-1000 mt-[5px] lg:mt-0 pl-[3px]">
                    {item.title}
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AniToEarnTapToEarn;
