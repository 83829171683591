import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronUp from "../../component/icons/ChevronUp";
import ChevronDown from "../../component/icons/ChevronDown";

const AniTokenomicAnalytics = () => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  const pointList = [
    {
      title: t("ani chart title 1"),
      content: t("ani chart content 1"),
    },
    {
      title: t("ani chart title 2"),
      content: t("ani chart content 2"),
    },
    {
      title: t("ani chart title 3"),
      content: t("ani chart content 3"),
    },
    {
      title: t("ani chart title 4"),
      content: t("ani chart content 4"),
    },
  ];

  const percentageList = ["30%", "50%", "5%", "15%"];

  const amountList = [
    t("3btoken"),
    t("5btoken"),
    t("1.5btoken"),
    t("0.5btoken"),
  ];

  const formatData = (data) => {
    if (!data) return null;
    return data.split("\n").map((item, index) => {
      // Regular expression to identify fully capitalized words
      const regex = /\b([A-Z]{2,})\b/g;

      // Split the line into words and apply different styles/classes
      const formattedContent = item.split(regex).map((word, idx) => {
        if (regex.test(word)) {
          // If the word is fully capitalized, apply different styling
          return (
            <span
              key={idx}
              className="text-[#FFFFFF] text-lg lg:text-xl xl:text-2xl font-normal font-bigNoodle"
            >
              {word + " "}
            </span>
          );
        } else if (word.trim() !== "") {
          // Render non-empty words normally (trim to remove extra spaces)
          return <div className="mb-[2rem]"><span key={idx}>{word}</span></div>;
        } else {
          // Render empty words as empty spans to avoid unintended spaces
          return <span key={idx}></span>;
        }
      });

      return (
        <React.Fragment key={index}>
          <div>{formattedContent}</div>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="relative h-auto w-screen max-w-[1920px] flex flex-col items-center py-[8rem] mt-[-5px]">

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      {/* Radial Gradient */}
      <div className="absolute inset-0 bg-[radial-gradient(circle,_#035D9F_0%,_#000000_70%,_transparent_100%)] pointer-events-none bg-[transparent] z-[-1]"></div>

      <div className="flex flex-col justify-center items-center gap-[1rem]">
        <h1 className="hidden lg:flex text-center text-5xl text-[#FFFFFF] font-normal font-bigNoodle tracking-wider uppercase">
          {t("anitokenomic analytics title")}
        </h1>
        <h1 className="lg:hidden text-center text-5xl text-[#FFFFFF] font-normal font-bigNoodle tracking-wider uppercase">
          {formatStringBreaks(t("anitokenomic analytics title"))}
        </h1>
        <p className="w-[80%] lg:w-[50%] text-[#FFFFFF] text-center text-sm font-medium font-outfit transition-opacity duration-1000">
          {t("anitokenomic analytics desc")}
        </p>
      </div>

      <div className="relative w-full h-auto flex flex-col items-center justify-center mt-[2rem] lg:mt-[6rem]">
        <img
          className="hidden lg:flex w-[80%] object-cover"
          src="/assets/anitoken/analytics-bg-element.webp"
          alt="Background"
        />
        <img
          className="flex lg:hidden w-[100%] max-w-96 object-cover"
          src="/assets/anitoken/analytics-bg-element-mobile.webp"
          alt="Background"
        />
        <img
          className="absolute right-[-5%] lg:right-[8%] bottom-[-20%] lg:bottom-[20%] z-[1000] w-[150px] lg:w-[400px] floating-element"
          src="/assets/anitoken/analytics-element-1.webp"
          alt=""
        />
        <img
          className="absolute left-[-5%] lg:left-[5%] bottom-[-10%] lg:bottom-[15%] z-[1000] w-[150px] lg:w-[400px] floating-element"
          src="/assets/anitoken/analytics-element-2.webp"
          alt=""
        />
        <div className="lg:absolute lg:bottom-[11%] flex flex-col justify-center items-center mt-[4rem] lg:mt-0">
          <h1 className="text-center text-3xl lg:text-3xl text-[#FFFFFF] font-normal font-bigNoodle tracking-wider uppercase">
            {t("anitokenomic analytics sub title")}
          </h1>
          <p className="text-[#FFFFFF] text-center text-base font-medium font-outfit transition-opacity duration-1000">
            {t("anitokenomic analytics sub desc")}
          </p>
        </div>
      </div>

      <div className="hidden lg:flex flex-col w-[60%] h-auto">
          {pointList.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col my-[0.5rem] transition-all duration-500 pl-[1rem] border-l-[4px] ${expandedIndex === index ? "border-[#205B79]" : "border-[#49DEFF]"}`}
              onClick={() => toggleExpand(index)}
            >
              <div className="flex justify-between items-center h-full">
                <div className="flex items-center">
                  <p className="text-[#FFFFFF] text-2xl xl:text-3xl font-bigNoodle uppercase">
                    {item.title}
                  </p>
                </div>
                <div className="flex flex-row ">
                  <p className="text-[#FFFFFF] text-xl xl:text-2xl font-bigNoodle">
                    {percentageList[index]}
                  </p>
                  <p className="text-[#FFAA00] text-xl xl:text-2xl font-bigNoodle ml-[1rem]">
                    {amountList[index]}
                  </p>
                  <button 
                    className={`z-[999] text-1xl font-bold ml-[1rem]  transition-all duration-300 ease-in-out ${expandedIndex === index ? "-rotate-180" : ""}`}
                    onClick={() => toggleExpand(index)}>
                    <ChevronDown fill={"#FFAA00"} width="12px"/>
                  </button>
                </div>
              </div>
              <div className={`${expandedIndex === index ? "max-h-[700px] opacity-100" : "max-h-0 opacity-0"} transition-all duration-500 ease-in-out overflow-hidden pt-[1rem] pb-[1rem] text-sm xl:text-lg mt-4 text-[#FFFFFF] font-outfit font-light`}>
                {formatData(item.content)}
              </div>
            </div>
          ))}
      </div>

    </div>
  );
};

export default AniTokenomicAnalytics;
