import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="max-w-[1920px] bottom-0 left-0 sticky flex flex-col items-center justify-center overflow-hidden z-[1002]">

    {/* Start and End Fade Out Overlays */}
    <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    
      <img
        src="/assets/icons/footer-rock.webp"
        className="w-screen mb-[-0.5rem] z-[2]"
        alt="animara icon"
      />
      <div className="bg-[#464B4F] flex flex-col w-full h-full items-center min-h-32 lg:mt-[-0.7rem] px-[2rem] xl:px-[8rem] py-[1.5rem] xl:py-[3rem] z-[1]">
        <div className="flex flex-row w-full my-auto justify-between items-center">
          <div className="flex items-center my-auto xl:mb-0">
            <Link to="/">
              <img
                src="/assets/icons/animara-logo-white.webp"
                className="max-h-10 max-w-[32dvw] lg:w-[20dvw] xl:w-full hover:scale-110 transition-all duration-300"
                alt="animara icon"
              />
            </Link>
          </div>
          <div className="">
            <nav className="bg-transparent text-white">
              <ul className="flex flex-wrap w-full justify-center xl:space-x-[4rem]">
                <li className=" mx-2 xl:mx-0 hover:scale-110 transition-all duration-300">
                  <a href="https://www.instagram.com/animara_world/">
                    <img
                      src="/assets/icons/instagram.webp"
                      alt="Instagram"
                      className="w-8 xl:w-[2rem]"
                      onMouseEnter={(e) => (e.currentTarget.src = '/assets/icons/instagram-hover.webp')}
                      onMouseLeave={(e) => (e.currentTarget.src = '/assets/icons/instagram.webp')}
                    />
                  </a>
                </li>
                <li className=" mx-2 xl:mx-0 hover:scale-110 transition-all duration-300">
                  <a href="https://x.com/animara_world?s=21&t=JLXEqXsjvI27HDMew5RsEQ">
                    <img
                      src="/assets/icons/x.webp"
                      alt="Twitter"
                      className="w-8 xl:w-[2rem]"
                      onMouseEnter={(e) => (e.currentTarget.src = '/assets/icons/x-hover.webp')}
                      onMouseLeave={(e) => (e.currentTarget.src = '/assets/icons/x.webp')}
                    />
                  </a>
                </li>
                <li className="mx-2 xl:mx-0 hover:scale-110 transition-all duration-300">
                  <a href="https://t.me/animarachatgroup">
                    <img
                      src="/assets/icons/telegram.webp"
                      alt="Telegram"
                      className="w-8 xl:w-[2rem]"
                      onMouseEnter={(e) => (e.currentTarget.src = '/assets/icons/telegram-hover.webp')}
                      onMouseLeave={(e) => (e.currentTarget.src = '/assets/icons/telegram.webp')}
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-[#373C3F] flex flex-col xl:flex-row w-full items-center justify-between px-[2rem] xl:px-[8rem] py-[3rem]">
        <div className="text-white mb-4 xl:mb-0">
          <p className="text-sm lg:text-base">
            {t("animara all rights reserved")}
          </p>
        </div>
        <div className="flex flex-row">
          <Link to="/privacy-policy">
            <a className="text-[#C5C5C5] hover:text-[#FFC85A] text-sm lg:text-base font-normal font-outfit">
              {t("privacy policy")}
            </a>
          </Link>

          <p className="text-[#C5C5C5]">&nbsp; | &nbsp;</p>

          <Link to="/terms-and-conditions">
            <a className="text-[#C5C5C5] hover:text-[#FFC85A] text-sm lg:text-base font-normal font-outfit ">
              {t("t&c")}
            </a>
          </Link>

          <p className="text-[#C5C5C5]">&nbsp; | &nbsp;</p>

          <a 
            type="button"
            href="https://artwardens.com"
            className="text-[#C5C5C5] hover:text-[#FFC85A] text-sm lg:text-base font-normal font-outfit "
          >
            {t("credit")}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
